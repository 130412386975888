import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Logo from './logo.jpg';
import { OpenInNew } from '@material-ui/icons';

const styles = {
  root: {
    flexGrow: 1,
    marginTop: 70,
  },
  topButton: {
    marginRight: 24,
  },
  image: {
    height: 50,
    marginRight: 24,
  },
  hoveredImage: {
    height: 50,
    marginRight: 24,
    cursor: 'pointer',
  },
  openInNew: {
    marginLeft: 8,
  }
};

class Template extends Component {

    state = {
      hovered: false,
    }

    render() {
        const { classes, onPageChange } = this.props;
        return (
            <div className={classes.root}>
            <AppBar position="fixed" color="#00FF00">
                <Toolbar>
                  <a>
                  <img
                  src={Logo}
                  className={
                    (this.state.hovered)
                    ? classes.hoveredImage
                    : classes.image
                  }
                  onClick={() => onPageChange(0)}
                  onMouseEnter={() => this.setState({hovered: true})}
                  onMouseLeave={() => this.setState({hovered: false})}
                  />
                  </a>
                  {/* <Button color="inherit" className={classes.topButton} onClick={() => onPageChange(0)}>Home</Button> */}
                  <Button color="inherit" className={classes.topButton} onClick={() => onPageChange(1)}>Biography</Button>
                  <Button color="inherit" className={classes.topButton} onClick={() => onPageChange(2)}>Messages</Button>
                  <Button color="inherit" className={classes.topButton} onClick={this.openPhotos}>
                    Photos
                    <OpenInNew className={classes.openInNew}/>
                  </Button>
                </Toolbar>
            </AppBar>
            {this.props.children}
            </div>
        );
    }

    openPhotos = () => {
      window.open('https://photos.app.goo.gl/x1UCXr53tM92RMHW7');
    }
}

export default withStyles(styles)(Template);