import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import GiftBox from './giftbox.png';
import Button from '@material-ui/core/Button';

const styles = {
    root: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        // border: '2px solid red',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 50,
    },
    message: {
        textAlign: 'center',
        fontWeight: 'bolder',
        fontSize: 40,
    },
    image: {
        width: '25em',
        height: '25em',
    },
    clickHere: {
        margin: 16,
    }
}

class Home extends Component {

    render() {
        const { classes } = this.props
        return (
            <div className={classes.root}>
                <span className={classes.message}>Happy Birthday,<br/>Kaivallya!!</span>
                <span className={classes.clickHere}>Click this box to open your gift :))</span>
                <Button onClick={this.openZoom}>
                    <img src={GiftBox} className={classes.image}/>
                </Button>
            </div>
        );
    }

    openZoom = () => {
        window.open('https://berkeley.zoom.us/my/kaivallyasbday')
    }
}

export default withStyles(styles)(Home);